<template>
  <div>
    <v-row>
      <v-col cols="4"> <v-text-field v-model="ids" label="Client id" outlined></v-text-field></v-col>
      <v-col cols="8"> <v-text-field v-model="session" label="session" outlined></v-text-field></v-col>
    </v-row>
    <v-btn color="primary" :disabled="!ids || !session" @click="setcheckPos(0)"> fetch </v-btn>
    <div class="pt-6">
      <v-row v-for="(q, w, e) in resis" :key="e">
        <v-col cols="2"> {{ w }} ({{ q && q.length > 0 ? q.length : 0 }})</v-col>
        <v-col cols="10" @click="setCopy(q)">{{ q }} </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    ids: null,
    session: null,
    resis: {
      NorenWClient: {},
      NorenWClientTV: {},
      NorenWClientTP: {},
      NorenWClientWeb: {},
    },
  }),

  methods: {
    setCheck() {
      // console.log("setCheck");

      let config = {
        method: "post",
        url: `https://go.mynt.in/NorenWClientWeb/ValidateHsToken?LoginId=${this.ids.toUpperCase()}&token=7N2mh4VSRt3j0k4Ux3tQMqfv`,
        headers: {
          Cookie: "NWC_ID=66b0fe6554e955199672ffebe90c2e78467be9c1a0a550356be5f3166c328f14",
        },
      };

      let axiosThis = this;
      axios
        .request(config)
        .then((response) => {
          axiosThis.resis = response;
        })
        .catch((error) => {
          axiosThis.resis = error;
          console.log(error);
        });
    },
    setcheckPos(f) {
      let data = `jData={"uid":"${this.ids.toUpperCase()}","actid":"${this.ids.toUpperCase()}"}&jKey=${this.session}`;

      var point = f == 0 ? "NorenWClient" : f == 1 ? "NorenWClientTV" : f == 2 ? "NorenWClientTP" : f == 3 ? "NorenWClientWeb" : null;
      // NorenWClient
      // NorenWClientTV
      // NorenWClientTP
      // NorenWClientWeb
      if (f <= 3) {
        let config = {
          method: "post",
          url: `https://go.mynt.in/${point}/PositionBook`,
          data: data,
        };

        let axiosThis = this;
        axios
          .request(config)
          .then((response) => {
            axiosThis.resis[`${point}`] = response.data;
            axiosThis.setcheckPos(f + 1);
          })
          .catch((error) => {
            axiosThis.resis[`${point}`] = {...error.response.data};
            axiosThis.setcheckPos(f + 1);
            axiosThis.resis = {...axiosThis.resis};
          });
      }
    },
    setCopy(textToCopy) {
      navigator.clipboard
        .writeText(JSON.stringify(textToCopy))
        .then(() => {
          alert("Text copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy text:", err);
        });
    },
  },
};
</script>
