<template>
  <div>
    <!-- :loading="mainloader"  -->
    <v-card class="crd-trn ss-cards mb-4 overflow-hidden" width="100%">
      <v-card color="secbg" class="elevation-0 rounded-0 pt-2 px-md-0 px-3">
        <v-row no-glutters>
          <v-col cols="12" md="7" class="pb-1 pb-md-2">
            <v-list-item class="px-0 px-md-4">
              <v-list-item-avatar color="mainbg" size="56" class="my-0">
                <img v-if="imageicon" :src="imageicon" @error="imageLoadError" class="pa-1" :alt="menudata[0].cname" width="100%" />
                <span class="headline maintext--text font-weight-bold" v-else>{{ menudata[0] ? (menudata[0].cname ? menudata[0].cname : menudata[0].symname).slice(0, 1) : "" }}</span>
              </v-list-item-avatar>
              <v-list-item-content class="pt-3 pb-0">
                <v-list-item-title :class="menudata[0] ? 'maintext--text' : 'txt-trn'" class="fs-18 font-weight-bold mb-0 text-capitalize lh-14"> {{ menudata[0] ? (menudata[0].cname ? menudata[0].cname : menudata[0].symname) : "abcd" }}</v-list-item-title>
                <v-chip-group class="my-0 py-0">
                  <v-chip label small text-color="subtext" color="cardbg" class="font-weight-medium fs-10">
                    {{ menudata.f && menudata.f.industry ? menudata.f.industry : "Industry" }}
                  </v-chip>
                  <v-chip label small text-color="subtext" color="cardbg" class="font-weight-medium fs-10">
                    {{ menudata.f && menudata.f.market_cap_type ? menudata.f.market_cap_type : "Marketcap type" }}
                  </v-chip>
                </v-chip-group>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" md="5" class="text-md-right text-left pt-0 pt-md-5 pb-2 pl-4 pl-md-3">
            <p class="mb-0 mr-md-4 subtitle-1 font-weight-bold lh-24">
              ₹<span :id="`ssdove${stkltp}ltp`"> {{ menudata[0] && menudata[0].ltp ? menudata[0].ltp : "0.00" }}</span> <br class="d-none d-md-block" />
              <span class="fs-12" :id="`ssdove${stkltp}chpclr`" :class="menudata[0] ? (menudata[0].ch > 0 ? 'maingreen--text' : menudata[0].ch < 0 ? 'mainred--text' : 'subtext-text') : 'subtext-text'">
                <span :id="`ssdove${stkltp}ch`">{{ menudata[0] && menudata[0].ch ? `${menudata[0].ch}` : "0.00" }} </span>
                (<span :id="`ssdove${stkltp}chp`">{{ menudata[0] && menudata[0].chp ? `${menudata[0].chp}` : "0.00" }}</span
                >%)</span
              >
            </p>
          </v-col>
        </v-row>
      </v-card>
      <div v-if="lwchartis && menudata && menudata[0]" class="px-4">
        <LightweightChart :propstsym="menudata[0] ? menudata[0] : {}" />
      </div>
    </v-card>

    <v-card class="crd-trn ss-cards mb-4 pt-4 overflow-hidden" width="100%">
      <div>
        <v-row class="pt-md-3 pl-md-4 pr-md-7 flex-column-reverse flex-md-row">
          <v-col cols="12" md="4" class="px-7 pl-md-3 pr-md-5 pb-0">
            <v-row no-gluttters>
              <v-col cols="6" class="pt-0">
                <v-list-item class="px-0">
                  <v-list-item-content class="py-0">
                    <v-list-item-subtitle class="font-weight-regular fs-10 subtext--text mb-2">Market Cap </v-list-item-subtitle>
                    <v-list-item-title class="maintext--text font-weight-medium fs-12 mb-1"> {{ menudata.f && menudata.f.market_cap ? `${Number(menudata.f.market_cap).toLocaleString()}` : "0.00" }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="6" class="pt-0">
                <v-list-item class="px-0">
                  <v-list-item-content class="py-0">
                    <v-list-item-subtitle class="font-weight-regular fs-10 subtext--text mb-2">Volume </v-list-item-subtitle>
                    <v-list-item-title class="maintext--text font-weight-medium fs-12 mb-1" :id="`ssdove${stkltp}vol`"> {{ menudata[0] && menudata[0].volume ? `${menudata[0].volume.toLocaleString()}` : "0.00" }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="6">
                <v-list-item class="px-0">
                  <v-list-item-content class="pb-0">
                    <v-list-item-subtitle class="font-weight-regular fs-10 subtext--text mb-2">Open </v-list-item-subtitle>
                    <v-list-item-title class="maintext--text font-weight-medium fs-12 mb-1">
                      ₹<span :id="`ssdove${stkltp}op`"> {{ menudata[0] && menudata[0].open_price ? menudata[0].open_price : "0.00" }}</span></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="6">
                <v-list-item class="px-0">
                  <v-list-item-content class="pb-0">
                    <v-list-item-subtitle class="font-weight-regular fs-10 subtext--text mb-2">Close </v-list-item-subtitle>
                    <v-list-item-title class="maintext--text font-weight-medium fs-12 mb-1">
                      ₹<span :id="`ssdove${stkltp}cp`">{{ menudata[0] && menudata[0].close_price ? menudata[0].close_price : "0.00" }}</span></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </v-col>

              <v-col cols="12">
                <p class="font-weight-regular fs-10 subtext--text mb-0">High - Low</p>
                <div class="d-flex flex-row mb-1">
                  <span class="maintext--text font-weight-medium fs-12 pt-1 lh-24">
                    ₹<span :id="`ssdove${stkltp}hp`"> {{ menudata[0] && menudata[0].high_price ? menudata[0].high_price : "0.00" }}</span>
                  </span>
                  <v-card v-if="menudata[0]" width="100%" class="crd-trn elevation-0 px-2">
                    <v-slider hide-details thumb-color="maintext" color="subtext" v-model="menudata[0].ltp" readonly :min="menudata[0].low_price" :max="menudata[0].high_price" track-color="subtext"></v-slider>
                  </v-card>
                  <span v-else class="fs-12 pt-1 lh-24"> - </span>
                  <span class="maintext--text font-weight-medium fs-12 pt-1 lh-24 float-right">
                    ₹<span :id="`ssdove${stkltp}lp`"> {{ menudata[0] && menudata[0].low_price ? menudata[0].low_price : "0.00" }}</span>
                  </span>
                </div>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12">
                <p class="font-weight-regular fs-10 subtext--text mb-0">52 Weeks high - 52 Weeks low</p>
                <div class="d-flex flex-row mb-1">
                  <span class="maintext--text font-weight-medium fs-12 pt-1 lh-24">
                    {{ menudata[0] && menudata[0].w52h ? `₹${menudata[0].w52h}` : "0.00" }}
                  </span>
                  <v-card v-if="menudata[0]" width="100%" class="crd-trn elevation-0 px-2">
                    <v-slider hide-details thumb-color="maintext" color="subtext" v-model="menudata[0].ltp" readonly :min="menudata[0].w52l" :max="menudata[0].w52h" track-color="subtext"></v-slider>
                  </v-card>
                  <span v-else class="fs-12 pt-1 lh-24"> - </span>
                  <span class="maintext--text font-weight-medium fs-12 pt-1 lh-24 float-right">
                    {{ menudata[0] && menudata[0].w52l ? `₹${menudata[0].w52l}` : "0.00" }}
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="8" class="pa-md-0 pt-4 pb-0 px-6 pos-rlt">
            <v-card class="crd-trn elevation-0 rounded-0 ss-adv-chart mt-auto" v-if="menudata[0]" width="100%">
              <div class="pt-5">
                <p class="font-weight-bold fs-16 mb-0">Market Depth</p>
                <v-list-item class="px-0">
                  <v-list-item-content class="py-0">
                    <v-list-item-subtitle class="font-weight-regular fs-12 subtext--text mb-1 text-uppercase">Buy order Qty </v-list-item-subtitle>
                    <v-list-item-title class="font-weight-medium fs-14 mb-1">{{ menudata[0].bid_qty > 0 ? menudata[0].bid_qty : "0.00" }}%</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content class="py-0 text-right">
                    <v-list-item-subtitle class="font-weight-regular fs-12 subtext--text mb-1 text-uppercase">Sell order Qty </v-list-item-subtitle>
                    <v-list-item-title class="font-weight-medium fs-14 mb-1">{{ menudata[0].ask_qty > 0 ? menudata[0].ask_qty : "0.00" }}%</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-progress-linear v-if="(menudata[0] && menudata[0].bid_qty > 0) || menudata[0].ask_qty > 0" class="market-depth" color="maingreen" :value="menudata[0].bid_qty"></v-progress-linear>
                <v-progress-linear v-else color="#D9DBDC" value="100"></v-progress-linear>

                <v-row no-gluttters class="mt-3" v-if="menudata[0] && menudata[0].depth">
                  <v-col cols="6" class="py-0 pr-4" style="border-right: thin dashed #d9dbdc !important">
                    <p class="subtext--text fs-12 font-weight-medium mb-2">Quantity <span class="float-right maingreen--text">Bid</span></p>
                    <div class="pos-rlt mb-2 depthrow-b" v-for="(n, k) in menudata[0].depth.bids" :key="k">
                      <v-card class="elevation-0 px-2 crd-trn" style="z-index: 1">
                        <p class="mb-0 subtext--text fs-14 font-weight-medium">
                          {{ n.volume.toLocaleString() }} <span class="float-right">{{ n.price?.toFixed(2) }}</span>
                        </p>
                      </v-card>
                      <v-card class="elevation-0 rounded-0 py-3 pos-abs" style="left: 0; top: -1px; z-index: 0" color="secgreen" :width="`${(((n.volume - menudata[0].bitmax) / menudata[0].bitmax) * 100 + 100).toFixed(0)}%`"> </v-card>
                    </div>
                  </v-col>
                  <v-col cols="6" class="py-0 pl-4" style="border-left: thin dashed #d9dbdc !important">
                    <p class="subtext--text fs-12 font-weight-medium text-right mb-2"><span class="float-left mainred--text">Ask</span> Quantity</p>
                    <div class="pos-rlt mb-2 depthrow-a" v-for="(n, k) in menudata[0].depth.asks" :key="k">
                      <v-card class="elevation-0 px-2 crd-trn" style="z-index: 1">
                        <p class="mb-0 subtext--text fs-14 font-weight-medium text-right">
                          <span class="float-left">{{ n.price?.toFixed(2) }}</span> {{ n.volume.toLocaleString() }}
                        </p>
                      </v-card>
                      <v-card class="elevation-0 rounded-0 py-3 pos-abs" style="right: 0; top: -1px; z-index: 0" color="secred" :width="`${(((n.volume - menudata[0].askmax) / menudata[0].askmax) * 100 + 100).toFixed(0)}%`"> </v-card>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-card>
            <v-card v-if="menudata[0] && menudata[0].instname && (menudata[0].instname == 'UNDIND' || menudata[0].instname == 'COM')" class="pos-abs md-glasscard elevation-0" height="270px" width="100%">
              <div class="pos-cent">
                <p class="mb-0 fs-14 maintext--text font-weight-bold text-center">
                  Non-tradable symbol, <br />
                  no market depth data
                </p>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gluttters class="px-4 pt-0">
          <v-col cols="2">
            <v-list-item class="px-0">
              <v-list-item-content class="py-1">
                <v-list-item-subtitle class="font-weight-regular fs-10 subtext--text mb-2">Avg price </v-list-item-subtitle>
                <v-list-item-title class="maintext--text font-weight-medium fs-12 mb-1">
                  <span :id="`ssdove${stkltp}ap`">{{ menudata[0] && menudata[0].ap ? menudata[0].ap : "0.00" }}</span></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="2">
            <v-list-item class="px-0">
              <v-list-item-content class="py-1">
                <v-list-item-subtitle class="font-weight-regular fs-10 subtext--text mb-2">OI </v-list-item-subtitle>
                <v-list-item-title class="maintext--text font-weight-medium fs-12 mb-1">
                  <span :id="`ssdove${stkltp}oi`">{{ menudata[0] && menudata[0].oi ? menudata[0].oi : "0.00" }}</span></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="2">
            <v-list-item class="px-0">
              <v-list-item-content class="py-1">
                <v-list-item-subtitle class="font-weight-regular fs-10 subtext--text mb-2">Upper circuit level </v-list-item-subtitle>
                <v-list-item-title class="maintext--text font-weight-medium fs-12 mb-1">
                  <span :id="`ssdove${stkltp}uc`">{{ menudata[0] && menudata[0].uc ? menudata[0].uc : "0.00" }}</span></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="2">
            <v-list-item class="px-0">
              <v-list-item-content class="py-1">
                <v-list-item-subtitle class="font-weight-regular fs-10 subtext--text mb-2">Lower circuit level </v-list-item-subtitle>
                <v-list-item-title class="maintext--text font-weight-medium fs-12 mb-1">
                  <span :id="`ssdove${stkltp}lc`">{{ menudata[0] && menudata[0].lc ? menudata[0].lc : "0.00" }}</span></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="2">
            <v-list-item class="px-0">
              <v-list-item-content class="py-1">
                <v-list-item-subtitle class="font-weight-regular fs-10 subtext--text mb-2">Last trade qty </v-list-item-subtitle>
                <v-list-item-title class="maintext--text font-weight-medium fs-12 mb-1">
                  <span :id="`ssdove${stkltp}ltq`">{{ menudata[0] && menudata[0].ltq ? menudata[0].ltq : "0.00" }}</span></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="2">
            <v-list-item class="px-0">
              <v-list-item-content class="py-1">
                <v-list-item-subtitle class="font-weight-regular fs-10 subtext--text mb-2">Last trade time </v-list-item-subtitle>
                <v-list-item-title class="maintext--text font-weight-medium fs-12 mb-1">
                  <span :id="`ssdove${stkltp}ltt`">{{ menudata[0] && menudata[0].ltt ? menudata[0].ltt : "0.00" }}</span></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <v-card class="crd-trn ss-cards mb-2 overflow-hidden" width="100%">
      <div class="px-4 pt-4" v-if="stockreturns">
        <!-- <v-divider class="mb-4 d-none d-md-flex"></v-divider> -->
        <p class="font-weight-bold subtitle-2 mb-1">Returns</p>
        <v-row no-glutters class="mt-0 pb-6">
          <v-col cols="6" md="2" v-for="(r, l) in stockreturns ? stockreturns : 6" :key="l" class="pt-0">
            <v-card v-if="l == 0" :id="`ssdove${stkltp}rbg`" :color="menudata[0].ch > 0 ? 'secgreen' : menudata[0].ch < 0 ? 'secred' : 'secbg'" class="rounded-lg px-3 py-2 elevation-0 brd-1-solid-ccc text-center" width="100%">
              <p :id="`ssdove${stkltp}rchpclr`" :class="menudata[0].ch > 0 ? 'maingreen--text' : menudata[0].ch < 0 ? 'mainred--text' : 'subtext--text'" class="fs-18 font-weight-bold mb-2">
                <span :id="`ssdove${stkltp}rchp`">{{ r.returns ? (((menudata[0].ltp - r.returns) / r.returns) * 100).toFixed(2) : "0.00" }}</span
                >%
              </p>
              <p class="mb-0 fs-10 text-uppercase font-weight-medium">
                {{ r.type ? r.type : "---" }}
              </p>
            </v-card>
            <v-card v-else :color="((menudata[0].ltp - r.returns) / r.returns) * 100 > 0 ? 'secgreen' : ((menudata[0].ltp - r.returns) / r.returns) * 100 < 0 ? 'secred' : 'secbg'" class="rounded-lg px-3 py-2 elevation-0 brd-1-solid-ccc text-center" width="100%">
              <p :class="((menudata[0].ltp - r.returns) / r.returns) * 100 > 0 ? 'maingreen--text' : ((menudata[0].ltp - r.returns) / r.returns) * 100 < 0 ? 'mainred--text' : 'subtext--text'" class="fs-18 font-weight-bold mb-2">
                {{ r.returns ? (((menudata[0].ltp - r.returns) / r.returns) * 100).toFixed(2) : "0.00" }}%
              </p>
              <p class="mb-0 fs-10 text-uppercase font-weight-medium">
                {{ r.type ? r.type : "---" }}
              </p>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div class="px-4 pb-4" v-if="menudata.pivot && menudata.pivot.length > 0">
        <!-- <v-divider class="mb-4 d-none d-md-flex"></v-divider> -->
        <p class="font-weight-bold subtitle-2 mb-1">Pivot levels</p>
        <v-row no-glutters class="px-2">
          <v-col cols="2" class="px-0" v-for="(p, l) in menudata.pivot" :key="l">
            <v-card width="100%" class="crd-trn elevation-0 px-1">
              <p class="fs-14 mb-0 font-weight-medium pos-rlt" :class="l <= 2 ? '' : 'text-right'">{{ p.name }} <span v-if="l == 2" class="pos-abs" style="right: -10px">P</span></p>
              <v-card width="100%" :color="p.color" height="8px" class="elevation-0 pos-rlt">
                <v-icon v-if="menudata.pivot[l - 1] && menudata[0].ltp <= p.value && menudata[0].ltp >= menudata.pivot[l - 1].value" class="pos-abs" style="top: -4px" :style="`left: ${Math.trunc(((p.value - menudata[0].ltp) / p.value) * 100)}%;`" color="maintext" size="16"
                  >mdi-checkbox-blank-circle</v-icon
                >
              </v-card>
              <p class="fs-12 mb-0 font-weight-medium pos-rlt" :class="l <= 2 ? '' : 'text-right'">
                {{ Number(p.value) ? Number(p.value).toFixed(2) : "" }} <span v-if="l == 2" class="pos-abs" style="right: -32px">{{ Number(menudata.pivotpoint) ? Number(menudata.pivotpoint).toFixed(2) : "" }}</span>
              </p>
            </v-card>
          </v-col>

          <!-- <v-col cols="2" class="px-0" v-for="(p, l) in menudata.pivot" :key="l">
            <v-card width="100%" class="elevation-0">
              <p class="fs-14 mb-0 font-weight-medium" :class="l <= 2 ? '' : 'text-right'">{{ p.name }}</p>
              <v-slider class="pivot" hide-details
                :thumb-color="(menudata.pivot[l - 1] && menudata[0].ltp <= p.value && menudata[0].ltp >= menudata.pivot[l - 1].value) ? '#000' : 'transparent'"
                v-model="menudata[0].ltp" readonly :max="p.value"
                :min="menudata.pivot[l - 1] ? menudata.pivot[l - 1].value : 0" :track-color="p.color"
                :color="p.color"></v-slider>
              <p class="fs-12 mb-0 font-weight-medium" :class="l <= 2 ? '' : 'text-right'">{{ Number(p.value) ?
                Number(p.value).toFixed(2) : '' }}</p>

            </v-card>
          </v-col> -->
        </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
import apiurl from "../../../apiurl.js";
import eventBus from "../../../eventBus.js";
import LightweightChart from "@/components/LightweightChart.vue";
// import { getLtpdata } from "../../../components/mixins/getAPIdata.js";

export default {
  data: () => ({
    mainloader: true,
    menudata: [],

    imageicon: null,

    stockreturns: [],
    stkltp: 0,

    lwchartis: false,
  }),
  mounted() {
    let local = localStorage.getItem("ssdtsym");
    if (local && local.includes(":")) {
      this.mainloader = true;
      this.clearData();
      this.setWaiting(localStorage.getItem("ssdtoken"), local.split(":")[0], local.split(":")[1]);
    }

    eventBus.$on("ssd-event", (type, token, exch, tsym) => {
      this.setWebsocket("unsub-D", [{token: this.menudata[0].token, exch: this.menudata[0].exch, tsym: this.menudata[0].tsym}], "ssd");

      this.mainloader = true;
      this.clearData();
      this.setWaiting(token, exch, tsym);
    });

    eventBus.$on("lwc-event", (data) => {
      this.lwchartis = data;
    });

    eventBus.$on("web-scoketConn", (data, page) => {
      if (page == "stockSSD") {
        this.optionChainDataParse(data);
      }
    });
  },
  beforeDestroy() {
    eventBus.$off("lwc-event");
    // eventBus.$off('ssd-event');
    // eventBus.$off('web-scoketConn');
  },
  components: {LightweightChart},

  methods: {
    setWaiting(token, exch, tsym) {
      if (window.ssddetail && window.ssddetail[0] && window.ssddetail[0].token == token) {
        this.setSingleData(token, exch, tsym);
      } else {
        setTimeout(() => {
          this.setWaiting(token, exch, tsym);
        }, 100);
      }
    },
    imageLoadError() {
      this.imageicon = null;
    },
    async setSingleData(token, exch, tsym) {
      let windata = window.ssddetail;
      this.imageicon = `${apiurl.imgicon}${tsym.split("-")[0]}.png`;

      this.menudata = [];
      // let ltpraw = await getLtpdata([{ "token": token, "exch": exch }]);
      var data = windata[1] ? windata[1] : "";
      this.mainloader = false;
      this.menudata.push(windata[0]);
      // this.menudata['g'] = ltpraw.data[`${token}`];
      // this.menudata.lp = Number(windata[0].lp) ? Number(windata[0].lp) : Number(this.menudata.g.lp)
      // this.menudata['ch'] = this.menudata.g ? (Number(this.menudata.g.lp) - Number(this.menudata.g.close)).toFixed(2) : '';
      // this.menudata['d'] = data.stockDescription ? data.stockDescription : '';

      if (data && data.fundamental && data.fundamental[0]) {
        this.menudata["f"] = data.fundamental[0];
      } else {
        this.menudata["f"] = [];
      }

      this.getReturns(token);
      this.setWebsocket("sub", [{token: token, exch: exch, tsym: tsym}], "ssd");
      this.stkltp = this.menudata[0] && this.menudata[0].token ? this.menudata[0].token : 0;
    },
    getReturns(token) {
      var windata = window.ssdreqdata.data;
      if (windata && windata[token] && windata[token].t) {
        this.stockreturns = [
          {type: "1 day", returns: this.menudata.close_price},
          {type: "1 week", returns: Number(windata[token].t.wk1_c) ? Number(windata[token].t.wk1_c) : 0},
          {type: "2 week", returns: Number(windata[token].t.wk2_c) ? Number(windata[token].t.wk2_c) : 0},
          {type: "1 month", returns: Number(windata[token].t.mnth1_c) ? Number(windata[token].t.mnth1_c) : 0},
          {type: "3 month", returns: Number(windata[token].t.mnth3_c) ? Number(windata[token].t.mnth3_c) : 0},
          {type: "1 year", returns: Number(windata[token].t.wk52_c) ? Number(windata[token].t.wk52_c) : 0},
        ];
        (this.menudata["pivot"] =
          Number(windata[token].t.sup_3) > 0 && Number(windata[token].t.sup_2) > 0 && Number(windata[token].t.sup_1) > 0 && Number(windata[token].t.res_1) > 0 && Number(windata[token].t.res_2) > 0 && Number(windata[token].t.res_3) > 0
            ? [
                {color: "#FF1717", name: "S3", value: Number(windata[token].t.sup_3) ? Number(windata[token].t.sup_3) : 0},
                {color: "#D34645", name: "S2", value: Number(windata[token].t.sup_2) ? Number(windata[token].t.sup_2) : 0},
                {color: "#E38988", name: "S1", value: Number(windata[token].t.sup_1) ? Number(windata[token].t.sup_1) : 0},
                {color: "#84C89D", name: "R1", value: Number(windata[token].t.res_1) ? Number(windata[token].t.res_1) : 0},
                {color: "#3FA965", name: "R2", value: Number(windata[token].t.res_2) ? Number(windata[token].t.res_2) : 0},
                {color: "#43A833", name: "R3", value: Number(windata[token].t.res_3) ? Number(windata[token].t.res_3) : 0},
              ]
            : []),
          (this.menudata["pivotpoint"] = Number(windata[token].t.pivot_point) ? Number(windata[token].t.pivot_point) : 0);
        // {'color': '', 'name': 'P', 'value': ''},
      } else {
        this.stockreturns = [];
        setTimeout(() => {
          this.getReturns(token);
        }, 100);
      }
    },

    clearData() {
      this.imageicon = null;
      this.menudata = [];
      this.stockreturns = [];
    },
    setWebsocket(flow, data, is) {
      eventBus.$emit("web-scoketOn", flow, data, is, "stockSSD");
    },
    optionChainDataParse(data) {
      if (this.menudata[0] && this.menudata[0].token == data.token) {
        this.menudata[0]["ltp"] = Number(data.lp) ? Number(data.lp).toFixed(2) : 0;
        this.menudata[0].lp = Number(data.lp) ? Number(data.lp).toFixed(2) : 0;

        this.menudata[0]["ch"] = Number(data.ch) > 0 || Number(data.ch) < 0 ? Number(data.ch).toFixed(2) : 0;
        this.menudata[0]["chp"] = Number(data.chp) > 0 || Number(data.chp) < 0 ? Number(data.chp).toFixed(2) : 0;
        this.menudata[0]["high_price"] = Number(data.high_price) > 0 || Number(data.high_price) < 0 ? Number(data.high_price).toFixed(2) : 0;
        this.menudata[0]["low_price"] = Number(data.low_price) > 0 || Number(data.low_price) < 0 ? Number(data.low_price).toFixed(2) : 0;
        this.menudata[0]["w52h"] = Number(data["w52h"]) > 0 || Number(data["w52h"]) < 0 ? Number(data["w52h"]).toFixed(2) : 0;
        this.menudata[0]["w52l"] = Number(data["w52l"]) > 0 || Number(data["w52l"]) < 0 ? Number(data["w52l"]).toFixed(2) : 0;
        this.menudata[0]["open_price"] = Number(data.open_price) > 0 || Number(data.open_price) < 0 ? Number(data.open_price).toFixed(2) : 0;
        this.menudata[0]["close_price"] = Number(data.prev_close_price) > 0 || Number(data.prev_close_price) < 0 ? Number(data.prev_close_price) : 0;
        this.menudata[0]["vol"] = Number(data.socketVolume) > 0 ? Number(data.socketVolume) : 0;
        this.menudata[0]["ap"] = data.ap ? Number(data.ap) : 0;

        this.menudata[0]["oi"] = data.oi ? Number(data.oi) : 0;
        this.menudata[0]["uc"] = data.uc ? Number(data.uc) : 0;
        this.menudata[0]["lc"] = data.lc ? Number(data.lc) : 0;
        this.menudata[0]["ltt"] = data.ltt ? data.ltt : "00:00:00";
        this.menudata[0]["ltq"] = data.ltq ? Number(data.ltq) : 0;

        // this.menudata[0]['ask'] = data.ask;
        // this.menudata[0]['bid'] = data.bid;
        this.menudata[0]["bid_qty"] = data.tbq > 0 || data.tsq > 0 ? (((data.tbq - (data.tbq + data.tsq)) / (data.tbq + data.tsq)) * 100 + 100).toFixed(2) : 0;
        this.menudata[0]["ask_qty"] = data.tbq > 0 || data.tsq > 0 ? Math.abs(100 - this.menudata[0].bid_qty).toFixed(2) : 0;
        this.menudata[0]["depth"] = data.depth;
        this.menudata[0]["bitmax"] = Math.max(...this.menudata[0].depth.bids.map((o) => o.volume));
        this.menudata[0]["askmax"] = Math.max(...this.menudata[0].depth.asks.map((o) => o.volume));
        // this.menudata[0].depth.asks = this.menudata.depth.asks.sort((a, b) => a.price - b.price)
        this.menudata[0].depth.bids = this.menudata[0].depth.bids.sort((a, b) => b.price - a.price);

        let tag = document.getElementById(`ssdove${data.token}ltp`);
        if (tag) {
          tag.innerHTML = this.menudata[0].ltp;
          document.getElementById(`ssdove${data.token}ch`).innerHTML = this.menudata[0].ch;
          document.getElementById(`ssdove${data.token}chp`).innerHTML = this.menudata[0].chp;
          eventBus.$emit("color-event", `ssdove${data.token}chpclr`, this.menudata[0].ch > 0 ? "maingreen--text" : this.menudata[0].ch < 0 ? "mainred--text" : "subtext--text");
          document.getElementById(`ssdove${data.token}oi`).innerHTML = this.menudata[0].oi;
          document.getElementById(`ssdove${data.token}uc`).innerHTML = this.menudata[0].uc;
          document.getElementById(`ssdove${data.token}lc`).innerHTML = this.menudata[0].lc;
          document.getElementById(`ssdove${data.token}ltt`).innerHTML = this.menudata[0].ltt;
          document.getElementById(`ssdove${data.token}ltq`).innerHTML = this.menudata[0].ltq;

          document.getElementById(`ssdove${data.token}vol`).innerHTML = this.menudata[0].vol;
          document.getElementById(`ssdove${data.token}ap`).innerHTML = this.menudata[0].ap;
          document.getElementById(`ssdove${data.token}hp`).innerHTML = this.menudata[0].high_price;
          document.getElementById(`ssdove${data.token}lp`).innerHTML = this.menudata[0].low_price;
          document.getElementById(`ssdove${data.token}op`).innerHTML = this.menudata[0].open_price;
          document.getElementById(`ssdove${data.token}cp`).innerHTML = this.menudata[0].close_price;
        }

        let tago = document.getElementById(`ssdove${data.token}rbg`);
        if (tago) {
          document.getElementById(`ssdove${data.token}rchp`).innerHTML = this.menudata[0].chp;
          eventBus.$emit("color-event", `ssdove${data.token}rchpclr`, this.menudata[0].ch > 0 ? "maingreen--text" : this.menudata[0].ch < 0 ? "mainred--text" : "subtext--text");
          tago.style.backgroundcolor = this.menudata[0].ch > 0 ? "#FBFFFA" : this.menudata[0].ch < 0 ? "#FFFCFB" : "#FAFAFA";
        }

        this.$set(this.menudata, 0, this.menudata[0]);
      }
    },
  },
};
</script>

<style>
.depthrow-b,
.depthrow-a {
  border: 1px solid transparent !important;
  cursor: pointer !important;
  border-radius: 3px !important;
}

.depthrow-b:hover {
  border: 1px solid var(--maingreen) !important;
}

.depthrow-a:hover {
  border: 1px solid var(--mainred) !important;
}

.pivot .v-slider--horizontal .v-slider__track-container {
  height: 8px !important;
}

.pivot .v-slider--horizontal {
  min-height: 16px !important;
}
</style>
