<template>
    <div id="app" class="  ">
        <v-card class="elevation-0 mb-4 mb-md-14 pa-4 pa-md-6 rounded-lg" color="#148564">
            <v-row no-glutters>
                <v-col md="4" cols="12">
                    <p class="fs-26 d-md-none font-weight-bold lh-24 white--text">NewsGenius</p>
                    <p class="fs-36 d-none d-md-flex font-weight-bold lh-32 white--text">NewsGenius</p>
                    <p class="title white--text mb-0 lh-24 font-weight-regular">Revolutionizing news with AI, fast,
                        accurate, and personalized.</p>
                    <!-- <v-btn
                                class="elevation-0 white--text font-weight-bold px-6 rounded-pill text-none fs-14 mb-2"
                                large color="#000000">Invest in Bonds</v-btn> -->
                </v-col>
                <v-col cols="7" class="d-none ml-auto mr-4 d-md-flex pos-rlt">
                    <div style="width: 100%" no-glutters class="pos-abs d-inline-flex flex-row-reverse">
                        <v-card width="170px" class="pt-8 mr-3 pb-3 stk-land-crds rounded-xl text-center">
                            <img src="@/assets/Bonds/main-card-3.svg" class="px-1 pb-4" alt="main-card-3" width="50%" />
                            <p class="fs-16 font-weight-bold lh-16 px-4 mt-1 mb-0">
                                AI-Powered <br />
                                News, Always <br />
                                Accurate
                            </p>
                        </v-card>

                        <v-card width="170px" class="pt-8 mr-3 pb-3 stk-land-crds rounded-xl text-center">
                            <img src="@/assets/Bonds/main-card-2.svg" class="px-1 pb-4" alt="main-card-2" width="58%" />
                            <p class="fs-16 font-weight-bold lh-16 px-4 mt-1 mb-0">
                                Smart Stories, <br />
                                Tailored <br />
                                For You
                            </p>
                        </v-card>

                        <v-card width="170px" class="pt-8 mr-3 pb-3 stk-land-crds rounded-xl text-center">
                            <img src="@/assets/Bonds/main-card-1.svg" class="px-1 pb-4" alt="main-card-1" width="50%" />
                            <p class="fs-16 font-weight-bold lh-16 px-4 mt-1 mb-0">
                                Revolutionizing <br />
                                News With <br />
                                Innovation
                            </p>
                        </v-card>
                    </div>
                </v-col>
            </v-row>
        </v-card>
        
        <div class="d-flex " style="margin-top: 120px;">

            <p class="title font-weight-bold mb-0 mb-md-3">NewsGenius </p>

            <!-- <p class="ml-auto mr-10">dfghj</p> -->
            <!-- <v-select  class="ml-auto mr-8" :items="['Time']" filled label="Filled style" dense></v-select> -->
            <v-tooltip top color="black">
                <template v-slot:activator="{ on, attrs }">
                    <div class="ml-auto mr-5" v-bind="attrs" v-on="on">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                    <v-icon color="maintext" class="mr-1">mdi-flip-h mdi-sort-variant</v-icon>
                                </div>
                            </template>
                            <v-list dense>
                                <v-list-item-group @change="NewsFilterFun()" v-model="newsfilter"
                                    active-class="primary--text" class="ma-2">
                                    <v-list-item v-for="(item, index) in newsfil" :key="index">
                                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-menu>
                    </div>
                </template>
                <span>Sort by</span>
            </v-tooltip>
        </div>
        <!-- <v-divider class=" mx-2"></v-divider> -->
        <v-card class="mt-4  elevation-0 pa-8" style="height: 100%; width: 98%; background-color: #E5EBEC;" rounded
            outlined>
            <!-- {{MainData}} -->
            <v-expansion-panels>
                <v-expansion-panel style="border-radius: 20px; background-color: white;" class="mb-4"
                    v-for="(data, index) in sortedData" :key="index">
                    <v-expansion-panel-header style="background-color: white;">
                        <v-card data-aos="fade-left" class="elevation-0"
                            style="height: 100px; border-radius: 10px ; width: 100%; ws">
                            <v-row>
                                <v-col cols="2">
                                    <img class="pa-2 ml-4 mt-1" style="border-radius: "
                                        :src="'https://images.unsplash.com/photo-1518770660439-4636190af475'"
                                        alt="Image" width="120px" height="90px"  />

                                </v-col>
                                <v-col>
                                    <div class="ml-8 mt-4">
                                        <div class="row">
                                            <div>
                                                <p class="caption mt-3 mb-0 grey--text">
                                                    {{ data.time.split(",")[0] }} {{ data.time.split(",")[1] }}
                                                </p>
                                                <p style="font-size: 11px;" class="mb-1 grey--text">
                                                    {{ data.time.split(",")[2] }}
                                                </p>
                                            </div>

                                            <!-- Loop through sectors and display v-chips directly in template -->
                                            <div>
                                                <v-chip
                                                    v-for="(item, chipIndex) in data.sector.split(',').map(s => s.trim())"
                                                    :key="chipIndex" small class="mt-4 ml-3" color="blue" outlined>
                                                    {{ item }}
                                                </v-chip>
                                            </div>
                                            <p :class="{
                                                'red--text': Number(data.score) < 0,
                                                'green--text': Number(data.score) > 0,
                                                'black--text': Number(data.score) === 0
                                            }" class="mb-0 mt-4 mr-10 font-weight-bold ml-auto body-2">
                                                {{ data.score }}
                                            </p>
                                        </div>
                                        <p style="margin-left: -10px;" class="body-2 mt-4 black--text font-weight-bold">
                                            {{ data.title }}
                                        </p>
                                    </div>
                                </v-col>
                            </v-row>
                            <!-- <div class="d-flex pa-1 "  > -->
                            <!-- <img class="" :src="'https://images.unsplash.com/photo-1518770660439-4636190af475'" alt="Image" style="border-radius: 10px;" width="125px" height="70%" /> -->

                            <!-- </div> -->
                        </v-card>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-divider></v-divider>

                        <v-card class="pa-4 elevation-0"
                            style="background-color: transparent; border-radius: 10px; width: 100%;">
                            <p data-aos="fade-left" class="body-2 mt-6 ml-6 black--text  ">
                                <span class="mb-1 grey--text body-2 pr-2">

                                </span> {{ data.summary }}
                            </p>
                            <v-row class="mb-1">
                                <v-col class="ma-2">
                                    <v-card class="elevation-0 "
                                        style="padding: 1px;   height: 150px ; background-color: #ECF8F1;  border-radius: 10px; overflow: hidden;">
                                        <p data-aos="fade-left" class="body-2 mt-6 ml-6    " style="color: green;">
                                            {{ data.positive_impact }}
                                        </p>
                                    </v-card>
                                </v-col>
                                <v-col class="ma-2">
                                    <v-card class="elevation-0"
                                        style="padding: 1px; ;width: 100%; height: 150px ; background-color: #FCF3F3 ; border-radius: 10px; overflow: hidden;">
                                        <p data-aos="fade-left" class="body-2 mt-6 ml-6   " style="color: red;">
                                            <span class="mb-1 red--text body-2 pr-2">

                                            </span> {{ data.negative_impact }}
                                        </p>
                                    </v-card>
                                </v-col>

                            </v-row>
                            <a class="ml-4 mt-8" v-if="data.link != null" target="_blank" :href="data.link">{{
                                data.source }}
                                >>></a>


                        </v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>

        </v-card>

        <!-- <header style="margin-bottom: -50px;">
            <div>
                <h1>New AI</h1>
                <p>Scroll horizontally to stack the cards. Scroll back to unstack them.</p>
            </div>
        </header> -->
        <!-- <main>
         
            <div id="cards-container">
                <ul id="cards">
                    <li v-for="(card, index) in MainData.news" :key="index" :id="`card-${index + 1}`" class="card">
                        <div class="card-content">
                            <div>
                                <span class="caption" style="color: #666666;">{{ card.published_time }}</span>
                                
                               <p class="mb-0 " style="font-size: 32px;">{{ card.title }}</p>
                               <p class="headline mb-0">Summary :</p>

                                <p style="color: #666666;"  class="body-1 mb-0">{{ card.summary }}</p>
                                <v-divider></v-divider>
                                <p class="headline mb-0">Impact :</p>
                                <p style="color: #666666;" class="body-1 mb-0">{{ card.impact }}</p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </main> -->
    </div>
</template>

<script>
// import { number } from 'echarts';

// AOS.init();

export default {
    data() {
        return {
            newsfilter : 0,
            sortedData : [],
            News_data: [
                {
                    "news_image": "https://images.unsplash.com/photo-1518770660439-4636190af475",
                    "news_msg": "Global markets rally as central banks signal easing inflation concerns.",
                    "news_type": "Market"
                },
                {
                    "news_image": "https://images.unsplash.com/photo-1581092330842-a6f842ab30b7",
                    "news_msg": "Bitcoin surpasses $107,000 amid 'relentless' ETF demand.",
                    "news_type": "Crypto"
                },
                {
                    "news_image": "https://images.unsplash.com/photo-1556740749-887f6717d7e4",
                    "news_msg": "Tech stocks soar after positive quarterly earnings reports.",
                    "news_type": "Stocks"
                },
                {
                    "news_image": "https://images.unsplash.com/photo-1518770660439-4636190af475",
                    "news_msg": "Oil prices decline amid fears of oversupply in global markets.",
                    "news_type": "Commodities"
                },
                {
                    "news_image": "https://images.unsplash.com/photo-1517248135467-4c7edcad34c4",
                    "news_msg": "Federal Reserve keeps interest rates unchanged, eyes 2025 for cuts.",
                    "news_type": "Policy"
                },
                {
                    "news_image": "https://images.unsplash.com/photo-1518770660439-4636190af475",
                    "news_msg": "USD weakens against major currencies following economic slowdown fears.",
                    "news_type": "Forex"
                },
                {
                    "news_image": "https://images.unsplash.com/photo-1556740749-887f6717d7e4",
                    "news_msg": "Fintech startup raises $200M in Series C funding to expand operations.",
                    "news_type": "Startup"
                },
                {
                    "news_image": "https://images.unsplash.com/photo-1517248135467-4c7edcad34c4",
                    "news_msg": "Gold prices hit a 5-month high as investors seek safe havens.",
                    "news_type": "Commodities"
                },
                {
                    "news_image": "https://images.unsplash.com/photo-1581092330842-a6f842ab30b7",
                    "news_msg": "Upcoming IPO of a major tech company expected to raise $2 billion.",
                    "news_type": "IPO"
                },
                {
                    "news_image": "https://images.unsplash.com/photo-1518770660439-4636190af475",
                    "news_msg": "Retail sector reports mixed earnings amid holiday shopping season.",
                    "news_type": "Earnings"
                },
                {
                    "news_image": "https://images.unsplash.com/photo-1581092330842-a6f842ab30b7",
                    "news_msg": "New European regulations on crypto assets set to take effect in January.",
                    "news_type": "Regulation"
                },
                {
                    "news_image": "https://images.unsplash.com/photo-1556740749-887f6717d7e4",
                    "news_msg": "Two major banks announce merger to create a $1 trillion institution.",
                    "news_type": "Merger"
                },
                {
                    "news_image": "https://images.unsplash.com/photo-1517248135467-4c7edcad34c4",
                    "news_msg": "AI investments surge as companies race to integrate generative AI solutions.",
                    "news_type": "Technology"
                },
                {
                    "news_image": "https://images.unsplash.com/photo-1518770660439-4636190af475",
                    "news_msg": "Global economic growth forecast downgraded amid geopolitical tensions.",
                    "news_type": "Economy"
                },
                {
                    "news_image": "https://images.unsplash.com/photo-1581092330842-a6f842ab30b7",
                    "news_msg": "Green energy stocks rise as governments increase renewable energy subsidies.",
                    "news_type": "Renewable"
                }
            ],

            MainData: [],
            cards: [
                {
                    title: "Card One",
                    description:
                        "This is the content of card one. Lorem ipsum dolor sit amet consectetur adipisicing elit.",
                    image: "https://assets.codepen.io/210284/flower-9.jpg",
                },
                {
                    title: "Card Two",
                    description:
                        "This is the content of card two. Lorem ipsum dolor sit amet consectetur adipisicing elit.",
                    image: "https://assets.codepen.io/210284/flower-8.jpg",
                },
                {
                    title: "Card Three",
                    description:
                        "This is the content of card three. Lorem ipsum dolor sit amet consectetur adipisicing elit.",
                    image: "https://assets.codepen.io/210284/flower-7.jpg",
                },
                {
                    title: "Card Four",
                    description:
                        "This is the content of card four. Lorem ipsum dolor sit amet consectetur adipisicing elit.",
                    image: "https://assets.codepen.io/210284/flower-6.jpg",
                },
            ],
            newsfil: [
                { key: "a", text: "Time -  New to Old" },
                { key: "z", text: "Time -  Old to New" },
                { key: "nav-h", text: "Score -  High to Low" },
                { key: "nav-l", text: "Score -  Low to Hight" },
                // { key: "y-h", text: "3yr rtn - High to Low" },
                // { key: "y-l", text: "3yr rtn - Low to High" },
            ],
            sortOrder: "asc",
        };
    },
    methods: {
        NewsFilterFun(){
            if (this.newsfilter == 0) {
                this.sortData("tn")
            }else if (this.newsfilter == 1) {
                this.sortData("to")
                
            }else if (this.newsfilter == 2) {
                this.sortData("sh")
                
            }else if (this.newsfilter == 3) {
                this.sortData("sl")
                
            }
        },
        sortData(order) {
            if (order == 'tn' || order == 'to') {
                this.sortedData = [...this.MainData].sort((a, b) => {
                    const dateA = new Date(a.time.replace(" IST", ""));
                    const dateB = new Date(b.time.replace(" IST", ""));
                    return order === "tn" ? dateB - dateA : dateA - dateB;
                });
            } else if (order == 'sh' || order == 'sl') {
                this.sortedData = [...this.MainData].sort((a, b) => {
                    return order === "sh" ? b.score - a.score : a.score - b.score;
                });
            }

        },
        mountedData() {
            const axios = require('axios');

            let config = {
                method: 'post',
                 
                url: 'http://192.168.5.163:9001/getNews',
                headers: {}
            };
            let axiosthis = this
            axios.request(config)
                .then((response) => {
                    // console.log(JSON.stringify(response.data));
                    axiosthis.MainData = response.data
                    console.log(axiosthis.MainData, 'popo');
                    axiosthis.sortedData = response.data
                    axiosthis.newsfilter = 0
                    axiosthis.sortData("tn")


                })
                .catch((error) => {
                    console.log(error);
                });

        },
        // sortchange() {
        //     console.log("lklklklk");

        // },

         
    },
    computed: {
       
    },
    mounted() {
        this.mountedData()
    }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@400;700&family=DM+Mono:wght@400;500&display=swap");

:root {
    --card-width: 40vw;
    --card-margin: 4vw;
    --card-left-offset: 1em;
    --numcards: 4;
    --outline-width: 0px;
}

body {
    background: #131212;
    color: beige;
    text-align: center;
    padding-bottom: 20vh;
    overflow-x: hidden;
}

header,
main {
    width: 80vw;
    margin: 0 auto;
}

header {
    height: 35vh;
    display: grid;
    place-items: center;
    margin-bottom: 80px;
}

#cards-container {
    overflow-x: auto;
    /* white-space: nowrap; */
}

#cards {
    list-style: none;
    display: flex;
    gap: var(--card-margin);
    padding-bottom: calc(var(--numcards) * var(--card-left-offset));
    margin-bottom: var(--card-margin);
}

.card {
    position: sticky;
    left: 0;
    padding-left: calc(var(--index) * var(--card-left-offset));
}

.card-content {
    box-shadow: 0 0.2em 1em rgba(0, 0, 0, 0.1), 0 1em 2em rgba(0, 0, 0, 0.1);
    background: #fffaf2;
    color: #131212;
    border-radius: 2rem;
    overflow: hidden;
    display: grid;
    grid-template-areas: "text img";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    align-items: stretch;
    padding: 1.4em;
    /* width: var(--card-width); */
}

.card-content>div {
    grid-area: text;
    width: 700px;
    height: 400px;
    /* min-width: 800px; */
    /* place-self: center; */
    /* text-align: left; */
    display: grid;
    gap: 1em;
    place-items: start;
}





h1 {
    font-weight: 300;
    font-size: 3rem;
    margin-bottom: 1rem;
    font-family: "Cormorant Garamond", serif;
}

h2 {
    font-weight: bold;
    font-size: 2.5rem;
    font-family: "Cormorant Garamond", serif;
    margin: 0;
}

p {
    font-weight: 300;
    line-height: 1.42;
    font-size: 1.1rem;
}

/* Animation */
@supports (animation-timeline: works) {
    @scroll-timeline cards-element-scrolls-in-body {
        source: selector(body);
        scroll-offsets: selector(#cards) start 1, selector(#cards) start 0;
        start: selector(#cards) start 1;
        end: selector(#cards) start 0;
        time-range: 4s;
    }

    .card {
        --index0: calc(var(--index) - 1);
        --reverse-index: calc(var(--numcards) - var(--index0));
        --reverse-index0: calc(var(--reverse-index) - 1);
    }

    .card-content {
        transform-origin: 0% 50%;
        will-change: transform;

        --duration: calc(var(--reverse-index0) * 1s);
        --delay: calc(var(--index0) * 1s);

        animation: var(--duration) linear scale var(--delay) forwards;
        animation-timeline: cards-element-scrolls-in-body;
    }

    @keyframes scale {
        to {
            transform: scale(calc(1.1 - calc(0.1 * var(--reverse-index))));
        }
    }
}
</style>