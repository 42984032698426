<template>
    <div id="timeline" class="timeline">
        <v-snackbar class="d-none d-md-flex snakbar-sty rounded-pill mt-16" transition="slide-x-reverse-transition" top
            right v-model="snackbar" timeout="4000" :value="true" :color="snackcolor" absolute text-color="white">
            <v-icon class="mr-2" color="#fff">mdi-alert-outline</v-icon>
            {{ snacktxt }}
            <v-icon @click="snackbar = false" class="float-right" color="#fff">mdi-close-circle</v-icon>
        </v-snackbar>
        <v-card class="elevation-0 mb-4 mb-md-14 pa-4 pa-md-6 rounded-lg" color="#0050C9">
            <v-row no-glutters>
                <v-col md="4" cols="12">
                    <p class="fs-26 d-md-none font-weight-bold lh-24 white--text">NewsGenius</p>
                    <p class="fs-36 d-none d-md-flex font-weight-bold lh-32 white--text">NewsGenius</p>
                    <p class="title white--text mb-0 lh-24 font-weight-regular">Revolutionizing news with AI, fast,
                        accurate, and personalized.</p>

                </v-col>
                <v-col cols="7" class="d-none ml-auto mr-4 d-md-flex pos-rlt">
                    <div style="width: 100%" no-glutters class="pos-abs d-inline-flex flex-row-reverse">
                        <v-card width="170px" class="pt-8 mr-3 pb-3 stk-land-crds rounded-xl text-center">
                            <img src="@/assets/Bonds/main-card-3.svg" class="px-1 pb-4" alt="main-card-3" width="50%" />
                            <p class="fs-16 font-weight-bold lh-16 px-4 mt-1 mb-0">
                                AI-Powered <br />
                                News, Always <br />
                                Accurate
                            </p>
                        </v-card>

                        <v-card width="170px" class="pt-8 mr-3 pb-3 stk-land-crds rounded-xl text-center">
                            <img src="@/assets/Bonds/main-card-2.svg" class="px-1 pb-4" alt="main-card-2" width="58%" />
                            <p class="fs-16 font-weight-bold lh-16 px-4 mt-1 mb-0">
                                Smart Stories, <br />
                                Tailored <br />
                                For You
                            </p>
                        </v-card>

                        <v-card width="170px" class="pt-8 mr-3 pb-3 stk-land-crds rounded-xl text-center">
                            <img src="@/assets/Bonds/main-card-1.svg" class="px-1 pb-4" alt="main-card-1" width="50%" />
                            <p class="fs-16 font-weight-bold lh-16 px-4 mt-1 mb-0">
                                Revolutionizing <br />
                                News With <br />
                                Innovation
                            </p>
                        </v-card>
                    </div>
                </v-col>
            </v-row>
        </v-card>
        <v-card v-if="uid" class="elevation-3 mb-4 mb-md-14 pa-4 pa-md-6  rounded-lg" color="#F1F3F8" style="  position: fixed;
            width: 56vw;
            bottom: -57px;
            margin-left: 65px;
             z-index: 100; height:125px;">
            <p class="body-1 black--text mb-0  lh-24 font-weight-regular">Here add your news link for Summarize</p>

            <v-row class="mt-3">

                <v-col cols="10" class="pr-4">
                    <v-text-field dense :readonly="btnloading" v-model="newsurl" @keyup.enter="AddNewsApi()" required
                        flat hide-details solo background-color="white"
                        class="sign-up-filed elevation-0 rounded-pill caption mb-3" label="Enter URL to summarize">
                        <template #prepend-inner>
                            <v-icon color="black">mdi-magnify</v-icon>
                        </template>

                    </v-text-field>




                </v-col>
                <v-col cols="2">
                    <v-btn dense @click="AddNewsApi" :loading="btnloading" :disabled="!newsurl" type="submit" block
                        :color="!newsurl ? '#FFF07E' : '#FFF07E'" class="text-none rounded-pill elevation-0"><span
                            class="black--text font-weight-bold">Submit</span></v-btn>
                </v-col>
            </v-row>
        </v-card>
        <div class="d-flex mb-6" style="margin-top: 40px;">

            <p class=" mr-auto title font-weight-bold mb-0 mb-md-3">NewsGenius </p>

            <!-- <p class="ml-auto mr-10">dfghj</p> -->
            <!-- <v-select  class="ml-auto mr-8" :items="['Time']" filled label="Filled style" dense></v-select> -->
            <!-- <v-tooltip left color="black">
                <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                        <v-icon v-if="uid" :disabled="btnloading" @click="AddNewsDialogue = true, newsurl = ''"
                            class="mr-3" color="maintext">mdi-plus</v-icon>
                    </div>
                </template>
                <span v-if="btnloading"> Adding News</span>
                <span v-else> Add News</span>
            </v-tooltip> -->

            <!-- <v-tooltip top color="black">
                <template v-slot:activator="{ on, attrs }">
                    <div class=" mr-5" v-bind="attrs" v-on="on">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                    <v-icon color="maintext" class="mr-1">mdi-flip-h mdi-sort-variant</v-icon>
                                </div>
                            </template>
                            <v-list dense>
                                <v-list-item-group @change="NewsFilterFun()" v-model="newsfilter"
                                    active-class="primary--text" class="ma-2">
                                    <v-list-item v-for="(item, index) in newsfil" :key="index">
                                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-menu>
                    </div>
                </template>
                <span>Sort by</span>
            </v-tooltip> -->
        </div>
        <!-- <v-divider></v-divider> -->
        <!-- <div class="btn-group">
            <button v-if="!expand" class="btn" @click="expandAll"><v-icon class="timeline__arrow-icon"
                    viewBox="0 0 24 24">mdi
                    mdi-chevron-down</v-icon></button>
            <button v-if="!collapse" class="btn" @click="collapseAll"><v-icon class="timeline__arrow-icon"
                    viewBox="0 0 24 24">mdi mdi-chevron-left</v-icon></button>
        </div> -->
        <div v-for="(item, index) in sortedData" :key="index" class="timeline__item  " style="width: 80%;">
            <div class="timeline__item-header ml-9">
                <!-- <button class="timeline__arrow" :id="'item' + index" :aria-labelledby="'item' + index + '-name'"
                    :aria-expanded="item.expanded.toString()" :aria-controls="'item' + index + '-ctrld'"
                    :aria-haspopup="true" @click="toggleItem(index)">
                    <v-icon class="timeline__arrow-icon" viewBox="0 0 24 24">mdi mdi-chevron-down</v-icon>
                     
                </button> -->
                <span class="timeline__dot  pa-2 "><span class="white--text ">{{ item.score }}</span></span>
                <!-- @click="toggleItem(index)" -->
                <div data-aos="fade-left">
                    <span class="mb-0  body-2 ">{{ item.time }}
                        <!--                         
                        <v-chip v-if="item.tag == 'useradd'"  
                            small class="pa-2 mr-2 ml-2 mt-1" color="orange" outlined>
                            Manual Added
                        </v-chip> -->


                    </span>
                    <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                                <p class="mb-0 mt-4 text-ellipsis" style="
                        font-size: 28px;
                        text-align: justify;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    ">
                                    {{ item.title }}
                                </p>
                            </div>
                        </template>
                        <v-card class="pa-4" style="width: 20vw; background-color: #F1F3F8;  ">
                            <span class="black--text"> {{ item.title }} </span>
                        </v-card>

                    </v-tooltip>


                    <div v-if="item.sector" class="mt-3">
                        <span v-for="(item, chipIndex) in item.sector.split(',').map(s => s.trim())" :key="chipIndex"
                            style="border-radius: 4px; padding: 0px 6px; background-color: var(--secbg) !important"
                            class="mr-1 table-hov-prd">
                            <span class=" fs-10 lh-16" v-text="item ? item : ''"> </span>
                        </span>
                        <!-- <v-chip v-for="(item, chipIndex) in item.sector.split(',').map(s => s.trim())" :key="chipIndex"
                            small class="pa-2 mr-2 mt-1" color="blue" outlined>
                            {{ item }}
                        </v-chip> -->
                    </div>

                </div>



            </div>
            <div :id="'item' + index + '-ctrld'" class="timeline__item-body" role="region"
                :aria-labelledby="'item' + index" :aria-hidden="(!item.expanded).toString()"
                :class="{ 'timeline__item-body--expanded': item.expanded }">
                <div class="timeline__item-body-content">
                    <v-card class="pa-4 elevation-0" style=" border-radius: 10px; width: 100%; ">
                        <p style="color: #4a4b4d;line-height: 1.8;text-align: justify;" data-aos="fade-left"
                            class="body-2 mt- ml-6   ">
                            {{ item.summary }}
                        </p>
                        <v-row class="mb-4">
                            <v-col class="ma-2">
                                <v-card class="elevation-0" style="padding: 1px; width: 100%; height: 148px; background-color: #ECF8F1; 
                                            border-radius: 10px; overflow: hidden;">
                                    <p data-aos="fade-left" style="color: #4a4b4d; line-height: 1.8; text-align: justify; 
                                        display: -webkit-box; -webkit-box-orient: vertical; 
                                        -webkit-line-clamp: 5; /* Number of lines visible */
                                        overflow: hidden;" class="body-2 pa-4">
                                        {{ item.positive_impact }}

                                    </p>
                                </v-card>

                            </v-col>
                            <v-col class="ma-2">
                                <v-card class="elevation-0" style="padding: 1px; width: 100%; height: 148px; background-color: #FCF3F3; 
                                            border-radius: 10px; overflow: hidden;">
                                    <p data-aos="fade-left" style="color: #4a4b4d; line-height: 1.8; text-align: justify; 
                                        display: -webkit-box; -webkit-box-orient: vertical; 
                                        -webkit-line-clamp: 5; /* Number of lines visible */
                                        overflow: hidden;" class="body-2 pa-4">
                                        {{ item.negative_impact }}

                                    </p>
                                </v-card>
                            </v-col>

                        </v-row>



                    </v-card>
                    <!-- {{ item.tag }} -->
                    <div class="d-flex" v-if="item.tag == 'useradd'">
                        <a class="ml-8  mr-auto" v-if="item.link != null" target="_blank" :href="item.link">{{
                            item.source
                        }}
                            >>></a>


                    </div>
                    <div class="d-flex" style="height: 30
                    px;" v-else>
                        <a class="ml-8  mr-auto" v-if="item.link != null" target="_blank" :href="item.link">{{
                            item.source
                        }}
                            >>></a>
                        <div class="ml-8  mr-auto" v-else>

                        </div>
                        <!-- <v-btn @click="shareTweet" class="mr-5 ml-2 text-capitalize white--text" solo text rounded flat
                          dense style="background-color: black;"><img src="@/assets/xmark.svg" width="12px" class="ml-1"
                            alt="Portfolio icon" /></v-btn> -->
                       
                       
                        <!-- {{ item.dislike && JSON.parse(item.dislike).includes(uid) }} -->
                        <!-- {{ item.dislike ? ((JSON.parse(item.dislike)).length) : [] }} -->
                        <!-- {{  JSON.parse(item.dislike) }} -->
                        <!-- {{  JSON.parse(item.dislike).includes(uid) }} -->
                        <!-- @click="likesend(false,item.idx_no)" -->

                        <!-- {{  JSON.parse(item.like) }} -->
                        <v-tooltip top color="black">
                            <template v-slot:activator="{ on, attrs }">
                               
                                <v-btn v-bind="attrs" v-on="on" small icon class="pa-2  ">
                                    <v-icon size="16" @click="shareTweet(item)" class=" "> mdi-share</v-icon>
                                     
                                        
                                </v-btn>


                            </template>
                            <span class="pa-2">Share</span>
                        </v-tooltip>
                        <v-tooltip top color="black">
                            <template v-slot:activator="{ on, attrs }">
                               
                                <v-btn v-bind="attrs" v-on="on" small icon class="pa-2  ">
                                    <v-icon size="18" @click="copyurl(item)" class=" ">mdi-content-copy</v-icon>
                                     
                                        
                                </v-btn>


                            </template>
                            <span class="pa-2">Copy</span>
                        </v-tooltip>
                        <v-tooltip top color="black">
                            <template v-slot:activator="{ on, attrs }">
                               
                                <v-btn v-bind="attrs" v-on="on" small icon class="pa-2  ">
                                    
                                    <v-icon size="20" @click="likesend('', item.idx_no)"
                                        v-if="item.dislike && JSON.parse(item.like).includes(uid)" class=" ">
                                        mdi-thumb-up

                                    </v-icon>

                                    <!-- {{  JSON.parse(item.dislike) }} -->
                                    <v-icon size="18" @click="likesend(true, item.idx_no)" v-else
                                        class=" ">mdi-thumb-up-outline</v-icon>
                                        
                                </v-btn>


                            </template>
                            <span class="pa-2">Like</span>
                        </v-tooltip>
                        <p class="mb-0 caption grey--text pt-3 ml-n1 mr-1" >{{ item.like ?
                                    ((JSON.parse(item.like)).length) : 0
                                    }}</p>
                        <v-tooltip top color="black">
                            <template v-slot:activator="{ on, attrs }">
                               
                                <v-btn  v-bind="attrs" v-on="on" small icon class="pa-2">

                                    <v-icon size="20" @click="likesend('', item.idx_no)"
                                        v-if="item.like && JSON.parse(item.dislike).includes(uid)" class="">
                                        mdi-thumb-down</v-icon>
                                    <v-icon size="18" @click="likesend(false, item.idx_no)" v-else
                                        class=" ">mdi-thumb-down-outline</v-icon>
                                </v-btn>


                            </template>
                            <span class="pa-2"> Unlike </span>
                        </v-tooltip>

                      
                        <!-- @click="likesend(true,item.idx_no)" -->
                        <!-- {{  JSON.parse(item.dislike) }} -->


                    </div>
                </div>
            </div>

        </div>
        <v-dialog v-model="AddNewsDialogue" width="420">
            <v-card class="pb-4 pt-2 px-6 overflow-hidden elevation-0" color="cardbg">
                <v-list-item-title class="font-weight-bold title maintext--text mt-2 mb-4">Add Your News Link<v-icon
                        @click="AddNewsDialogue = false" :disabled="btnloading" class="float-right"
                        color="maintext">mdi-close</v-icon></v-list-item-title>
                <p class="font-weight-regular fs-14 subtext-text mb-2">Enter Your News Link</p>
                <v-text-field hide-details height="40px" dense background-color="secbg" flat class="rounded-pill mb-4"
                    placeholder="News URL" type="text" solo v-model="newsurl">
                </v-text-field>
                <v-btn :disabled="!newsurl" :loading="btnloading" color="btnclr" @click="AddNewsApi"
                    class="text-none rounded-pill elevation-0 btntext--text px-6 float-right" height="40px"> Add
                </v-btn>
            </v-card>
            <!-- <v-card class="pb-4 pt-2 px-6 overflow-hidden elevation-0" color="cardbg">
                    <v-list-item-title class="font-weight-bold title maintext--text mt-2 mb-4">Create New List <v-icon
                            @click="createMWdialog = false" class="float-right"
                            color="maintext">mdi-close</v-icon></v-list-item-title>
                    <p class="font-weight-regular fs-14 subtext-text mb-2">Enter the watchlist name</p>
                    <v-text-field hide-details height="40px" dense background-color="secbg" flat
                        class="rounded-pill mb-4" placeholder="my first millions" v-on:keypress="isLetter($event)"
                        v-on:keyup.enter="addSearch('wls'), (createMWdialog = false)" type="text" solo
                        v-model="aaddtoMW.wl">
                    </v-text-field>
                    <v-btn @click="addSearch('wls'), (createMWdialog = false)" :disabled="!aaddtoMW.wl" color="btnclr"
                        class="text-none rounded-pill elevation-0 btntext--text px-6 float-right" height="40px"> Save
                    </v-btn>
                </v-card> -->
        </v-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            MainData: [],

            uid: '',
            collapse: true,
            newsurl: '',
            snackcolor: '',
            snackbar: false,
            snacktxt: '',
            btnloading: false,
            AddNewsDialogue: false,

            expand: false,
            sortedData: [],
            newsfilter: 0,
            errorMessages: 'required',
            newsfil: [
                { key: "tn", text: "Time -  New to Old" },
                { key: "to", text: "Time -  Old to New" },
                { key: "sh", text: "Score -  High to Low" },
                { key: "sl", text: "Score -  Low to Hight" },
                // { key: "y-h", text: "3yr rtn - High to Low" },
                // { key: "y-l", text: "3yr rtn - Low to High" },
            ],
            liked: false,
            dislike: false,
        };
    },
    methods: {
        copyurl(item) {
            this.copyObjectToClipboard(item)
            this.snacktxt = "News Copied"
            this.snackbar = true
            this.snackcolor = 'success'

        },
        shareTweet(item) {
            //console.log("Custom action: Tweet button clicked!");
            let text = this.copyObjectToClipboard(item)

            //   const twitterIntentUrl = "https://twitter.com/intent/tweet?text=custom+share+text&hashtags=example,demo&via=twitterdev&related=twitterapi,twitter";
            //   window.open(twitterIntentUrl, '_blank');
            const tweetUrl = "https://twitter.com/intent/tweet";
            // let urlfortweet = 'https://report.mynt.in/dailypnl?ucode=' + this.postalreadyucode

            const tweetParams = {
                // url : `https://report.mynt.in/dailypnl?ucode=${axiosthis.uniqueCode}`
                // url: "https://zebuetrade.com/",
                text: text,
                hashtags: "Traders",
                via: "zebuetrade",
                related: "twitterapi,twitter",
                // url : `http://localhost:8080/dailypnl?ucode=${this.uniqueCode}`,



            };

            const queryString = Object.keys(tweetParams)
                .map(
                    (key) =>
                        encodeURIComponent(key) + "=" + encodeURIComponent(tweetParams[key])
                )
                .join("&");

            window.open(`${tweetUrl}?${queryString}`, "_blank");
        },
        copyObjectToClipboard(item) {
            // Format the object as a string with keys as headings and values as subtitles
            let formattedText = '';

            // Arranging keys and values in a specific order
            const orderedObject = {
                Title: item.title,
                Time: item.time,
                Summary: item.summary,
                Sector: item.sector,
                Link: item.link,
                Negative_Impact: item.negative_impact,
                Positive_Impact: item.positive_impact,
            };

            for (const [key, value] of Object.entries(orderedObject)) {
                formattedText += `${key}:\n${value}\n\n`;
            }

            // Use the Clipboard API to copy the text
            navigator.clipboard.writeText(formattedText).then(
                () => console.log("Text copied to clipboard!"),
                (err) => console.error("Failed to copy text: ", err)
            );

            // Return the formatted text (for other uses)
            return formattedText;
        },
        likesend(val, index) {
            const axios = require('axios');
            let data = JSON.stringify({
                "cc": this.uid,
                "id": index,
                "like": val
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'http://192.168.5.163:9001/addLike',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            let axiosthis = this
            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                    if (response.data == 'Done') {
                        axiosthis.mountedData()

                    }
                })
                .catch((error) => {
                    console.log(error);
                });

        },
        likepress() {
            if (this.liked == true) {
                this.liked = false
            } else {
                this.liked = true

            }
        },
        unlikepress() {
            if (this.dislike == true) {
                this.dislike = false
            } else {
                this.dislike = true

            }
        },
        handleAppendClick() {
            console.log("Append icon clicked!");
        },
        AddNewsApi() {
            this.btnloading = true
            const urlPattern = /^(https?:\/\/[^\s$.?#].[^\s]*)$/i;
            urlPattern.test(this.newsurl);

            if (urlPattern.test(this.newsurl)) {
                const axios = require('axios');
                let data = JSON.stringify({
                    "cc": this.uid,
                    "link": this.newsurl
                });

                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: 'http://192.168.5.163:9001/getUserNews',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                let axiosthis = this
                axios.request(config)
                    .then((response) => {
                        console.log(JSON.stringify(response.data));
                        if (response.data.title != '') {
                            axiosthis.btnloading = false
                            // axiosthis.AddNewsDialogue = false 
                            axiosthis.snacktxt = "News Added"
                            axiosthis.snackbar = true
                            axiosthis.snackcolor = 'success'
                            axiosthis.newsurl = ''

                            axiosthis.mountedData()

                            // axiosthis.sortedData, push(
                            //     {
                            //         "link": this.newsurl,
                            //         "negative_impact":  response.data.impacts.negative[0],
                            //         "org_title": "NSE, BSE may remain open on Budget Day, exchanges in talks to ensure investor participation",
                            //         "positive_impact":   response.data.impacts.positive[0],
                            //         "score": "80",
                            //         "sector": "Finance, Stock Market",
                            //         "source": null,
                            //         "summary": response.data.summary,
                            //         "time": "Dec 18, 2024, 12:16 PM IST",
                            //         "title": response.data.title,
                            //         "expanded": false,
                            //         "tag" : "created"
                            //     }
                            // )
                        }

                    })
                    .catch((error) => {
                        console.log(error);
                        axiosthis.btnloading = false
                        axiosthis.snacktxt = error
                        axiosthis.snackbar = true
                        axiosthis.snackcolor = 'warning'
                    });

            } else {
                this.btnloading = false
                this.snacktxt = "Please provide the valid link"
                this.snackbar = true
                this.snackcolor = 'warning'
            }


        },
        NewsFilterFun() {
            if (this.newsfilter == 0) {
                this.sortData("tn")
            } else if (this.newsfilter == 1) {
                this.sortData("to")

            } else if (this.newsfilter == 2) {
                this.sortData("sh")

            } else if (this.newsfilter == 3) {
                this.sortData("sl")

            }
        },
        sortData(order) {
            if (order == 'tn' || order == 'to') {
                this.sortedData = [...this.MainData].sort((a, b) => {
                    const dateA = new Date(a.time.replace(" IST", ""));
                    const dateB = new Date(b.time.replace(" IST", ""));
                    return order === "tn" ? dateB - dateA : dateA - dateB;
                });
            } else if (order == 'sh' || order == 'sl') {
                this.sortedData = [...this.MainData].sort((a, b) => {
                    return order === "sh" ? b.score - a.score : a.score - b.score;
                });
            }

        },
        toggleItem(index) {
            const item = this.sortedData[index];
            item.expanded = !item.expanded;
        },
        expandAll() {
            this.expand = true
            this.collapse = false
            this.sortedData.forEach((item) => {
                item.expanded = true;
            });
        },
        collapseAll() {
            this.expand = false
            this.collapse = true
            this.sortedData.forEach((item) => {
                item.expanded = false;
            });
        },
        mountedData() {
            this.uid = sessionStorage.getItem("userid");
            const axios = require('axios');
            let data = JSON.stringify({
                "cc": this.uid
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'http://192.168.5.163:9001/getNews',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            let axiosthis = this
            axios.request(config)
                .then((response) => {
                    // console.log(JSON.stringify(response.data));
                    // console.log(response.data, "lolo");

                    response.data.news.forEach((element) => {
                        element['expanded'] = true;
                        // element['likeval'] = Array.isArray(response.data.like) && response.data.like.includes(this.uid);
                        // element['dislikeval'] = Array.isArray(response.data.dislike) && response.data.like.includes(this.uid);
                        // element['dislikevalcount'] = response.data.dislike.lem

                    });
                    console.log();



                    axiosthis.MainData = response.data.news
                    console.log(axiosthis.MainData, "loaollo");

                    axiosthis.newsfilter = 0
                    response.data.user_news.forEach(item => {
                        // Add 'tag' and 'expanded' properties to the item
                        item['tag'] = 'useradd';
                        item['expanded'] = true;

                        try {
                            // Parse the JSON from the `response` field
                            const jsonValue = JSON.parse(item.response);

                            // Safely extract properties if JSON parsing is successful
                            item['title'] = jsonValue.title || '';
                            item['summary'] = jsonValue.summary || '';
                            item['positive_impact'] = jsonValue.impacts?.positive?.[0] || '';
                            item['negative_impact'] = jsonValue.impacts?.negative?.[0] || '';
                        } catch (error) {
                            console.error("Error parsing JSON:", error.message);
                        }

                        // Add the updated item to MainData
                        axiosthis.MainData.push(item);
                    });

                    axiosthis.sortedData = axiosthis.MainData
                    axiosthis.sortData("tn")
                    console.log(axiosthis.sortedData, 'popo');

                    // axiosthis.MainData = response.data


                })
                .catch((error) => {
                    console.log(error);
                });

        },
    },
    mounted() {
        this.mountedData()
    }
};
</script>

<style scoped>
* {
    border: 0;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

:root {
    --hue: 223;
    --bg: hsl(var(--hue), 10%, 90%);
    --fg: hsl(var(--hue), 10%, 10%);
    --primary: hsl(var(--hue), 90%, 50%);
    --trans-dur: 0.3s;
    --trans-timing: cubic-bezier(0.65, 0, 0.35, 1);
    font-size: calc(16px + (24 - 16) * (100vw - 320px) / (2560 - 320));
}

a {
    color: var(--primary);
    transition: color var(--trans-dur);
}

body,
button {
    color: var(--fg);
    font: 1em/1.5 "IBM Plex Sans", sans-serif;
}

body {
    background-color: var(--bg);
    height: 100vh;
    transition:
        background-color var(--trans-dur),
        color var(--trans-dur);
}

h1 {
    font-size: 2em;
    margin: 0 0 3rem;
    padding-top: 1.5rem;
    text-align: center;
}

.btn {
    background-color: var(--fg);
    border-radius: 0.25em;
    color: var(--bg);
    cursor: pointer;
    padding: 0.375em 0.75em;
    transition:
        background-color calc(var(--trans-dur) / 2) linear,
        color var(--trans-dur);
    -webkit-tap-highlight-color: transparent;
}

.btn:hover {
    background-color: hsl(var(--hue), 10%, 50%);
}

.btn-group {
    display: flex;
    gap: 0.375em;
    margin-bottom: 1.5em;
}

.timeline {
    /* margin-left: 100px; */
    /* padding: 0 1.5em;
	width: 100%;
	max-width: 36em; */
}

.timeline__arrow {
    color: red;
    background-color: transparent;
    border-radius: 0.25em;
    cursor: pointer;
    flex-shrink: 0;
    margin-inline-end: 0.25em;
    outline: transparent;
    width: 2em;
    height: 2em;
    transition:
        background-color calc(var(--trans-dur) / 2) linear,
        color var(--trans-dur);
    -webkit-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.timeline__arrow:focus-visible,
.timeline__arrow:hover {
    background-color: hsl(var(--hue), 10%, 50%, 0.4);
}

.timeline__arrow-icon {
    display: block;
    pointer-events: none;
    transform: rotate(-90deg);
    transition: transform var(--trans-dur) var(--trans-timing);
    width: 100%;
    height: auto;
}

.timeline__date {
    font-size: 0.833em;
    line-height: 2.4;
}

.timeline__dot {
    background-color: currentColor;
    border-radius: 50%;
    display: inline-block;
    flex-shrink: 0;
    /* margin: 0.625em 0; */
    /* margin-inline-end: 1em; */
    margin-left: -10px;
    margin-right: 15px;
    position: relative;
    width: 2em;
    height: 2em;
}

.timeline__item {
    position: relative;
    padding-bottom: 2.25em;
}

.timeline__item:not(:last-child):before {
    background-color: currentColor;
    content: "";
    display: block;
    position: absolute;
    top: 1em;
    left: 2.625em;
    width: 0.125em;
    height: 100%;
    transform: translateX(-50%);
}

[dir="rtl"] .timeline__arrow-icon {
    transform: rotate(90deg);
}

[dir="rtl"] .timeline__item:not(:last-child):before {
    right: 2.625em;
    left: auto;
    transform: translateX(50%);
}

.timeline__item-header {
    display: flex;
}

.timeline__item-body {
    border-radius: 0.375em;
    overflow: hidden;
    margin-top: 0.5em;
    margin-inline-start: 4em;
    height: 0;
}

/* .timeline__item-body-content {
    background-color: hsl(var(--hue), 10%, 50%, 0.2);
    opacity: 0;
    padding: 0.5em 0.75em;
    visibility: hidden;
    transition:
        opacity var(--trans-dur) var(--trans-timing),
        visibility var(--trans-dur) steps(1, end);
} */

.timeline__meta {
    width: 100%;
}

.timeline__title {
    font-size: 1.5em;
    line-height: 1.333;
}

/* Expanded state */
.timeline__item-body--expanded {
    height: auto;
}

/* .timeline__item-body--expanded .timeline__item-body-content {
    opacity: 1;
    visibility: visible;
    transition-delay: var(--trans-dur), 0s;
} */

.timeline__arrow[aria-expanded="true"] .timeline__arrow-icon {
    transform: rotate(0);
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
    :root {
        --bg: hsl(var(--hue), 10%, 10%);
        --fg: hsl(var(--hue), 10%, 90%);
        --primary: hsl(var(--hue), 90%, 70%);
    }
}
</style>